const forJSON = msg => {
  let formatMessage = msg;
  if (!msg) {
    return msg;
  }
  if (typeof formatMessage !== 'string') {
    formatMessage = msg.props.children.join(' ');
  }
  return formatMessage
    .replace(/(<([^>]+)>)/ig, '')
    .replace(/"/g, '\\"')
    .replace(/{br}/ig, '')
    .replace(/\s+/g, ' ')
    .trim();
};

export default { forJSON };
